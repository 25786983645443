<template lang="pug">
div
  .calendly-skip-row.d-flex.align-items-center.justify-content-center(v-if="isConsultation")
    .text-center.brand-link-ternary(@click="goToDashboard()") {{ $t('onboarding.agency.3.skip') }}
  .get-started-block.mb-5.pb-5(v-if="!isConsultation")
    .brand-mobile-onboarding-title-sm.my-4 Now that you know all about us, let’s get started together!
    .get-started-item.d-flex.flex-column.mb-4.justify-content-center
      .text-center.mt-4.mb-3.font-size-1--25 Want to see the Partner Dashboard?
      button.btn.brand-btn-primary.brand-mobile-onboarding-btn-full(@click="bookCall") Book Agency demo call
    .separator.d-flex.justify-content-center
      span.px-3.py-2.font-size-1--25 or
    .get-started-item.d-flex.flex-column.mb-5
      .text-center.mt-4.mb-3.font-size-1--25 Want to test OptiMonk first as regular user?
      button.btn.brand-btn-primary.brand-mobile-onboarding-btn-full(@click="goToDashboard") Call go to Basic Dashboard
  .mobile-onboarding-calendly-wrapper
</template>

<script>
  import { agencyIndustries } from '@/config/agency';
  import { mapState, mapActions } from 'vuex';
  import { getAccountIdFromCookie } from '@/util';

  export default {
    name: 'AgencyBookOrCallScreen',
    data: () => ({ isConsultation: false }),
    computed: {
      ...mapState(['account']),
    },
    async created() {
      await this.fetchAccount();
    },
    beforeDestroy() {
      window.removeEventListener('message', this.onMessage);
    },
    methods: {
      ...mapActions(['fetchAccount', 'saveOnboardingStage']),
      bookCall() {
        const { firstName, lastName, phoneNumber, email } = this.account.login;
        const {
          website,
          phone,
          agency_industry: industry,
          clients_number: clients,
        } = this.account.settings.onboarding.additional;

        const domains = this.account.settings.domains;

        const customAnswers = {};

        customAnswers.a1 = phone || phoneNumber;
        customAnswers.a2 = website || (domains && domains.length ? domains[0].domain : undefined);
        customAnswers.a4 = industry
          ? agencyIndustries.findIndex((i) => i === industry) + 1
          : undefined;
        customAnswers.a5 = clients;

        setTimeout(() => {
          this.isConsultation = true;
        }, 3000);
        window.Calendly.initInlineWidget({
          url: 'https://calendly.com/csenge-karakas/optimonkpartnerprogram?utm_source=onboarding',
          parentElement: document.querySelector('.mobile-onboarding-calendly-wrapper'),
          prefill: {
            firstName,
            lastName,
            email,
            customAnswers,
          },
        });

        window.addEventListener('message', this.onMessage);
      },

      onMessage(e) {
        if (e.data.event && e.data.event.indexOf('calendly') === 0) {
          if (e.data.event === 'calendly.event_scheduled') {
            // this.isConsultation = false
            setTimeout(() => {
              this.saveOnboardingStage(null);
              this.$router.push({
                name: 'dashboard',
                params: { userId: getAccountIdFromCookie() },
              });
            }, 3500);
          }
        }
      },
      goToDashboard() {
        this.saveOnboardingStage(null);
        this.$router.push({ name: 'dashboard' });
      },
    },
  };
</script>

<style lang="sass">
  .separator
    position: relative
    &:before
      position: absolute
      top: 50%
      border-top: 1px dashed #D2D2D2
      content: ""
      display: block
      width: 100%
    span
      position: relative
      background: white
      color: #969BA0
      font-style: italic
  .get-started
    &-block
      button
        font-size: 1.125rem
    &-item
      max-width: 260px
      margin: auto
  .calendly-skip-row
    position: absolute
    bottom: 0
    left: 0
    width: 100%
    height: 3rem
    z-index: 11
    background: white
    box-shadow: 0px -4px 10px rgba(0, 0, 0, 0.1)
  .mobile-onboarding-calendly-wrapper
    .calendly-spinner
      position: absolute
      z-index: 9
      top: 0
      left: 0
      width: 100%
      height: 100%
    iframe[src*="calendly"]
      position: absolute
      z-index: 10
      top: 0
      left: 0
      width: 100%
      height: calc(100% - 3rem)
</style>
