<template lang="pug">
.d-flex.flex-column.align-items-center.justify-content-between
  .brand-mobile-onboarding-header
    om-logo-svg.mt-3.mb-3(width="138" height="27")
  .px-4.w-100
    transition(name="fade" mode="out-in")
      component(:is="type")
  .d-flex.px-4.pb-3.flex-column.justify-content-center.align-items-center.w-100.mt-3.mb-2(
    v-if="!isLast"
    style="flex-grow: 0"
  )
    button.btn.brand-btn-primary.brand-mobile-onboarding-btn-full.mb-0(
      key="button"
      @click="next"
      style="max-width: 16.25rem"
    ) {{ $t('next') }}
    button.btn.btn-default.brand-btn-link.brand-mobile-onboarding-btn-link.p-2(@click="last") Skip
</template>

<script>
  import Connect from './Screens/Connect.vue';
  import Benefit from './Screens/Benefit.vue';
  import GetStarted from './Screens/GetStarted.vue';

  const steps = [
    'we-can',
    'sell-more',
    'get-more-leads',
    'delight-visitors',
    'we-care',
    'custom-branding',
    'client-management',
    'support',
    'get-started',
  ];
  export default {
    /*
  { name: 'agency_benefits_we_can', path: 'we-can' },
  { name: 'agency_benefits_sell_more', path: 'sell-more' },
  { name: 'agency_benefits_get_more_leads', path: 'get-more-leads' },
  { name: 'agency_benefits_delight_visitors', path: 'delight-visitors' },
  { name: 'agency_benefits_we_care', path: 'we-care' },
  { name: 'agency_benefits_whitelabel', path: 'whitelabel' },
  { name: 'agency_benefits_client_management', path: 'client-management' },
  { name: 'agency_benefits_support', path: 'support' }
  */
    name: 'AgencyBenefitsMobile',
    components: { Connect, Benefit, GetStarted },
    computed: {
      step() {
        return this.$route.params.step;
      },
      type() {
        if (this.isLast) return 'GetStarted';
        return ['we-can', 'we-care'].includes(this.step) ? 'Connect' : 'Benefit';
      },
      current() {
        return steps.findIndex((step) => this.step === step);
      },
      isLast() {
        return this.current === steps.length - 1;
      },
    },
    methods: {
      next() {
        this.$router.push({ name: this.$route.name, params: { step: steps[this.current + 1] } });
      },
      last() {
        this.$router.push({ name: this.$route.name, params: { step: steps[steps.length - 1] } });
      },
    },
  };
</script>
