<template lang="pug">
  .d-flex.flex-column.align-items-center
    img(:class="{ mirrored: isWeCareScreen }" :src="require(`@/assets/admin/svg/${image}.svg`)" style="width: 13.5rem; max-height: 13.5rem")
    .font-weight-bold.font-size-2.text-center.mt-5 {{ $t(`agencyBenefits.titles.${this.$route.params.step}`) }}
</template>

<script>
  export default {
    name: 'WhatWeCanWelcome',
    computed: {
      isWeCareScreen() {
        return this.$route.params.step === 'we-care';
      },
      image() {
        if (this.isWeCareScreen) return 'onboarding/pointing-down';
        return 'pointing-monk';
      },
    },
  };
</script>

<style lang="sass">
  img.mirrored
    transform: scaleX(-1)
</style>
