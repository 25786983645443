<template lang="pug">
  .d-flex.flex-column.align-items-center
    h3.brand-mobile-onboarding-title-md.mt-4.mb-3 {{ $t(`agencyBenefits.titles.${this.step}`) }}
    .brand-mobile-onboarding-subtitle-sm.mb-2 {{ $t(`agencyBenefits.subtitles.${this.step}`) }}
    img(:src="require(`@/assets/admin/svg/agencyBenefits/${this.step}.svg`)")
</template>

<script>
  export default {
    name: 'WhatWeCanWelcome',
    computed: {
      step() {
        return this.$route.params.step;
      },
    },
  };
</script>
